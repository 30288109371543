
import { mapMutations } from 'vuex'
import html2canvas from 'html2canvas'
// import * as htmlToImage from 'html-to-image'

export default {
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    alignCenter: {
      type: Boolean,
      default: true
    },
    header: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      order: null,
      imageLoaded: false,
      showSaveButton: false
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    handleImageLoad () {
      // หลังจากรูปภาพโหลดเสร็จแล้ว
      // ให้ซ่อนหน้าโหลดโดยใช้ nuxt-loading module
      this.$nuxt.$loading.finish()
    },
    async saveImage () {
      try {
        const response = await fetch(this.base64Image)
        const blob = await response.blob()

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'image.jpg' // Specify the filename here
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = function () {
          link.href = reader.result
          link.click()
        }
      } catch (error) {
        console.error('Error saving image:', error)
      }
    },
    // downloadQrImage () {
    //   this.showLoading()
    //   console.time('html2canvas')
    //   html2canvas(document.querySelector('#testHTML'), {
    //     imageTimeout: 0,
    //     logging: true, // เพิ่มบรรทัดนี้เพื่อดูข้อมูล debug
    //     scale: 1 // สเกลของภาพ
    //   }).then(async (canvas) => {
    //     console.timeEnd('html2canvas')

    //     console.time('canvasToDataURL')
    //     const imageDataURL = canvas.toDataURL('image/png')

    //     console.timeEnd('canvasToDataURL')

    //     console.time('html2canvasDownloadLink')
    //     // สร้างลิงก์สำหรับดาวน์โหลดภาพ
    //     const link = document.createElement('a')
    //     link.href = imageDataURL
    //     link.download = `qr_${this.order.order_code}.png`
    //     // คลิกลิงก์เพื่อดาวน์โหลดภาพ
    //     link.click()
    //     console.timeEnd('html2canvasDownloadLink')
    //     await this.hideLoading()
    //   })
    // },
    // download () {
    //   this.showLoading()
    //   const testHTML = document.getElementById('htmlQR')
    //   htmlToImage.toJpeg(testHTML, { quality: 0.95, backgroundColor: '#ffffff' })
    //     .then(async (dataUrl) => {
    //       const link = document.createElement('a')
    //       link.download = `qr_${this.order.order_code}.jpeg`
    //       link.href = dataUrl
    //       link.click()
    //       //   this.downloadImage(dataUrl, true)
    //       await this.hideLoading()
    //     })
    //     .catch(async (error) => {
    //       await this.hideLoading()
    //       console.error('oops, something went wrong!', error)
    //     })
    // },
    async downloadQrImage (download = false) {
      if (download) {
        this.showLoading()
      }

      // ตรวจสอบว่ามีภาพในแคชหรือไม่
      const cachedImage = sessionStorage.getItem('cachedQrImage' + this.id)

      if (cachedImage) {
        this.showSaveButton = true
        // ถ้ามีให้ใช้ภาพจากแคชแทนการสร้างใหม่
        this.downloadImage(cachedImage, download)
        if (download) {
          await this.hideLoading()
        }
      } else {
        console.time('html2canvas')
        html2canvas(document.querySelector('#htmlQR'), {
          imageTimeout: 0,
          logging: true, // เพิ่มบรรทัดนี้เพื่อดูข้อมูล debug
          scale: 1.5 // สเกลของภาพ
        }).then(async (canvas) => {
          console.timeEnd('html2canvas')

          console.time('canvasToDataURL')
          const imageDataURL = canvas.toDataURL('image/png')
          console.timeEnd('canvasToDataURL')

          // บันทึกภาพลงในแคช
          sessionStorage.setItem('cachedQrImage' + this.id, imageDataURL)

          console.time('html2canvasDownloadLink')
          this.showSaveButton = true
          // ดาวน์โหลดภาพ
          this.downloadImage(imageDataURL, download)

          console.timeEnd('html2canvasDownloadLink')
          console.log('--- downloadQrImage success ---')

          if (download) {
            await this.hideLoading()
          }
        })
      }
    },
    downloadImage (imageDataURL, dowload = false) {
      const link = document.createElement('a')
      link.href = imageDataURL
      link.download = `qr_${this.order.order_code}.png`
      if (dowload) {
        link.click()
      }
    },
    imageLoadedSuccess () {
      this.downloadQrImage(false)
    }
  },
  async mounted () {
    try {
      this.showLoading()
      const { data } = await this.$axios.$get(`orders/${this.id}`)
      this.order = data
      this.imageLoaded = true
    } catch (e) {
      console.log('error', e.message)
      // this.$router.push({ name: 'my-orders' })
    }
    this.hideLoading()
    // this.downloadQrImage()

    this.$nextTick(() => {
      //   const qrImage = document.querySelector('#qr-image')
      //   // เพิ่ม event listener สำหรับการโหลดของภาพ
      //   qrImage.addEventListener('load', this.qrImageLoaded, function () {
      //     console.log('image loaded')
      //     this.downloadQrImage(false)
      //   })
      window.addEventListener('beforeunload', function () {
      // ลบข้อมูลที่ถูกบันทึกใน sessionStorage ก่อนที่เว็บไซต์จะปิดลง
        sessionStorage.removeItem('cachedQrImage' + this.id)
      })
    })
  },
  computed: {
    base64Image () {
      if (this.order.api_payment_response.qrImage) {
        return 'data:image/png;base64,' + this.order.api_payment_response.qrImage
      }
      return null
    },
    alignContent () {
      if (this.alignCenter) {
        return 'justify-content-center'
      }
      return ''
    }
  }
  // downloadQrImage () {
  //   // Select the HTML element to convert to PNG
  //   const element = document.getElementById('htmlQR')

  //   html2canvas(element).then(function (canvas) {
  //     // Convert the canvas to a blob
  //     canvas.toBlob(function (blob) {
  //       // Create a temporary link element
  //       const link = document.createElement('a')
  //       link.download = 'qr_' + this.order.order_code + '.png' // Set the download attribute
  //       link.href = URL.createObjectURL(blob) // Set the href attribute to the blob URL
  //       link.click() // Trigger a click event on the link to initiate the download
  //     }, 'image/png')
  //   })
  // }
}
