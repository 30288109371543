
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapMutations } from 'vuex'
import Swal from 'sweetalert2'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    orderId: {
      type: [String, Number],
      required: true,
      default: null
    },
    order: {
      type: [Object],
      required: false,
      default: null
    }
  },
  data () {
    return {
      orderData: null,
      errors: [],
      provinceData: [],
      typeOfBranchSelected: 1,
      taxPersonaSelected: 'individual_person',
      taxSelected: 0,
      requireWithholdTax: 0,
      sameAddressSelected: false,
      taxList: [
        { text: 'ไม่ต้องการ', value: 0 },
        { text: 'ต้องการ', value: 1 }
      ],
      typeOfBranch: [
        // { text: 'ไม่ระบุ', value: 1 },
        { text: 'สำนักงานใหญ่', value: 1 },
        { text: 'สาขา', value: 2 }
      ],
      sameAddress: [
        { text: 'ใช้ที่อยู่เดียวกับที่อยู่จัดส่ง', value: 1 },
        { text: 'กำหนดเอง', value: 0 }
      ],
      taxPersona: [
        { text: 'บุคคลธรรมดา', value: 'individual_person' },
        { text: 'นิติบุคคล', value: 'juristic_persons' }
        // { text: 'ราชการรัฐวิสาหกิจ', value: 'government_employee' }

      ],
      tax: {
        branch_no: '',
        tax_no: '',
        firstname: '',
        lastname: '',
        company_name: '',
        type_of_branch: '', // สำนักงานใหญ่ สาขา ไม่ระบุ
        email: ''
      },
      discounTaxService: 0,
      saveTax: true,
      loading: true
    }
  },
  computed: {
    taxName () {
      if (this.taxPersonaSelected === 'individual_person') {
        return 'เลขบัตรประจำตัวประชาชน'
      } else {
        return 'เลขประจำตัวผู้เสียภาษี *'
      }
    },
    taxCondition () {
      return this.taxPersonaSelected === 'juristic_persons' || this.taxPersonaSelected === 'government_employee'
    }
  },
  watch: {
    'orderData.tax_type': {
      handler (newVal, oldVal) {
        // if (newVal === 'company') {
        //   this.updatePrice()
        // }
      },
      deep: true // กำหนด deep เป็น true เพื่อให้ Watcher ตรวจสอบการเปลี่ยนแปลงในโครงสร้างซับซ้อน
    },
    sameAddressSelected (newVal) {
      if (newVal) {
        this.tax.address = this.orderData.address
        this.tax.province = this.orderData.province_id
        this.tax.zipcode = this.orderData.zipcode
      } else {
        this.tax.address = null
        this.tax.province = null
        this.tax.zipcode = null
        // this.tax.address = this.orderData.invoice_address
        // this.tax.province = this.orderData.invoice_province_id
        // this.tax.zipcode = this.orderData.invoice_zipcode
      }
    }
  },
  created () {
    this.orderData = this.order
  },
  async mounted () {
    this.showLoad()
    try {
      const { data } = await this.$axios.$get('provinces')
      this.provinceData = data
    } catch (error) {
      console.error(error)
    }

    await this.initTax()
    // await this.hideLoad()

    // console.log('[-] Tax order data', this.orderData)
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    showLoad () {
      // console.log('--- showLoad ---')
      this.showLoading()
    },
    hideLoad () {
      // console.log('--- hideLoad ---')
      this.loading = false
      this.hideLoading()
    },
    async initTax () {
      // console.log('---init tax---', this.orderData)
      if (typeof this.orderData.require_tax_invoice !== 'undefined') {
        this.taxSelected = await this.orderData.require_tax_invoice === null ? 0 : this.orderData.require_tax_invoice
        this.tax.require_tax_invoice = await this.orderData.require_tax_invoice
        // console.log('>>> taxSelected <<<', this.taxSelected)
      }
      if (typeof this.orderData.tax_type !== 'undefined') {
        if (this.orderData.tax_type === '1') {
          // console.log('---> 1 <---')
          this.taxPersonaSelected = await 'individual_person'
          this.tax.fullname = await this.orderData.tax_fullname
        } else if (this.orderData.tax_type === '2') {
          // console.log('---> 2 <---')
          this.taxPersonaSelected = await 'juristic_persons'
          this.tax.company_name = await this.orderData.tax_fullname
          this.typeOfBranchSelected = await this.orderData.tax_type_of_branch_id
          this.tax.type_of_branch = await this.orderData.tax_type_of_branch_id
          this.tax.branch_no = await this.orderData.tax_branch_no
        } else if (this.orderData.tax_type === '3') {
          // console.log('---> 3 <---')
          this.taxPersonaSelected = await 'government_employee'
          this.tax.company_name = await this.orderData.tax_fullname
          this.typeOfBranchSelected = await this.orderData.tax_type_of_branch_id
          this.tax.type_of_branch = await this.orderData.tax_type_of_branch_id
          this.tax.branch_no = await this.orderData.tax_branch_no
        }
      }

      if (typeof this.orderData.tax_id !== 'undefined') {
        this.tax.tax_number = await this.orderData.tax_id
      }

      if (typeof this.orderData.invoice_phone !== 'undefined') {
        this.tax.phone = await this.orderData.invoice_phone
      }

      if (this.sameAddressSelected && typeof this.orderData.invoice_address !== 'undefined') {
        this.tax.address = await this.orderData.invoice_address
      }

      if (this.sameAddressSelected && typeof this.orderData.invoice_province_id !== 'undefined') {
        this.tax.province = await this.orderData.invoice_province_id
      }

      if (this.sameAddressSelected && typeof this.orderData.invoice_zipcode !== 'undefined') {
        this.tax.zipcode = await this.orderData.invoice_zipcode
      }
      if (typeof this.orderData.userinfo.email !== 'undefined') {
        this.tax.email = await this.orderData.userinfo.email
      }

      this.loading = false
    },
    // updatePrice () {
    //   let totalService = 0
    //   if (this.orderData.tax_type === 'company') {
    //     console.log('>>> orderData <<<', this.orderData)
    //     const designPrice = this.$roundToDecimals(this.$roundToDecimals(this.$calculateExcludeVat(this.orderData.design_price), 3), 2)
    //     const printPlatePrice = this.$roundToDecimals(this.$roundToDecimals(this.$calculateExcludeVat(this.orderData.print_plate_price), 3), 2)
    //     const plateDiecutPrice = this.$roundToDecimals(this.$roundToDecimals(this.$calculateExcludeVat(this.orderData.plate_diecut_price), 3), 2)
    //     totalService = designPrice + printPlatePrice + plateDiecutPrice
    //   }
    //   this.$emit('update:totalService', totalService)
    // },
    async getOrderData () {
      try {
        const result = await this.$axios.$get(`orders/${this.orderId}`)
        if (result.success) {
          this.orderData = result.data
          this.$emit('update:order', this.orderData)
        }
      } catch (e) {
        console.log('error get order : ', e.message)
      }
    },
    async validateAndSubmit (e) {
      e.preventDefault()
      await this.$refs.form.validate().then(async (isValid) => {
        // console.log('isValid >>> ', isValid)
        // console.log('Validation Errors >>> ', this.$refs.form.errors)
        const invalidFieldNames = Object.keys(this.$refs.form.errors)

        // console.log('Invalid Field Names:', invalidFieldNames)

        // Optionally, log detailed errors for each field
        invalidFieldNames.forEach((fieldName) => {
        //   console.log(`${fieldName} Errors:`, this.$refs.form.errors[fieldName])
        })

        if (isValid) {
          await this.saveInvoice()
        }
      })
    },
    async saveInvoice (noti = true) {
      this.showLoading()
      const postData = {
        require_withhold_tax: this.requireWithholdTax,
        require_tax_invoice: this.taxSelected,
        tax_type: this.taxPersonaSelected,
        tax_no: this.tax.tax_number,
        company_name: this.tax.company_name,
        fullname: this.tax.fullname,
        phone: this.tax.phoneNumber,
        address: this.tax.address,
        province_id: this.tax.province,
        zipcode: this.tax.zipcode,
        type_of_branch: this.typeOfBranchSelected,
        branch_no: this.tax.branch_no,
        email: this.tax.email,
        save_log: noti
      }

      if (this.taxSelected === 0) {
        postData.fullname = this.orderData.name
        postData.address = this.orderData.address
        postData.province_id = this.orderData.province_id
        postData.zipcode = this.orderData.zipcode
      }

      try {
        const result = await this.$axios.$post(`order/${this.orderId}/save-tax-address`, postData)
        if (result.success === true) {
          this.saveTax = false
          this.errors = []
          await this.getOrderData()
          if (noti) {
            Swal.fire({
              title: result.message,
              icon: 'success'
            })
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        if (error.response.status === 422) {
          this.errors = Object.values(error.response.data.errors).flat()
        } else {
        // Handle other errors
        }

        this.$nextTick(() => {
          const refTaxError = this.$refs.refTaxError
          if (refTaxError) {
            refTaxError.scrollIntoView({ behavior: 'smooth' })
          }
        })
      }
      this.hideLoading()
    },
    async changeZipcodeData (value, type) {
    //   this.showLoading()
    //   try {
    //     const result = await this.$axios.$get('zipcode/get/province', { params: { zipcode: value } })
    //     if (result.status === true) {
    //       if (result.data === null) {
    //         Swal.fire({
    //           title: 'ไม่พบรหัสไปรษณีย์นี้ในระบบ',
    //           icon: 'warning'
    //         })
    //         this.hideLoading()
    //         return
    //       }
    //       if (type === 'address') {
    //         this.province = result.data.province_id
    //       } else {
    //         this.tax.province = result.data.province_id
    //       }
    //     }
    //   } catch (e) {
    //     console.log('error', e.message)
    //   }
    //   this.hideLoading()
    },
    handleKeyPress (evt) {
      if (evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
        evt.preventDefault()
      }
    }
  }
}
