import { render, staticRenderFns } from "./Tax.vue?vue&type=template&id=578e3e43&scoped=true"
import script from "./Tax.vue?vue&type=script&lang=js"
export * from "./Tax.vue?vue&type=script&lang=js"
import style0 from "./Tax.vue?vue&type=style&index=0&id=578e3e43&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "578e3e43",
  null
  
)

export default component.exports